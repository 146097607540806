import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';
import GuestRegisterForm, {
    GuestRegisterEvents
} from '@naturehouse/design-system/components/organisms/guest-register-form/GuestRegisterForm';
import { LoginDialogInterface, LoginState } from './LoginDialog';

export default class GuestRegisterDialog implements LoginDialogInterface {
    static #instance: GuestRegisterDialog | null = null;

    static getInstance(): GuestRegisterDialog {
        if (this.#instance === null) {
            this.#instance = new GuestRegisterDialog();
        }

        return this.#instance;
    }

    static destroy(): void {
        this.#instance = null;
    }

    readonly #dialog: Dialog;

    readonly #form: GuestRegisterForm;

    constructor() {
        this.#dialog = document.getElementById('guest-register-dialog') as Dialog;
        this.#form = this.#dialog?.querySelector(
            '[is="nh-guest-register-form"]'
        ) as GuestRegisterForm;

        if (this.#dialog === null || this.#form === null) {
            return;
        }

        this.#form.addEventListener(GuestRegisterEvents.REGISTER_SUCCESS, this.#handleSuccess);
    }

    public show(): void {
        this.#dialog.showModal();
    }

    public close(): void {
        this.#dialog.close();
        GuestRegisterDialog.destroy();
    }

    public successCallback = (): void => {
        // stub
    };

    readonly #handleSuccess = (): void => {
        document.body.classList.add(LoginState.USER_AVAILABLE);

        this.successCallback();

        this.#dialog.close();
    };
}
