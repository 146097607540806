import { Observer } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import CSRFTokenCollection from '../CSRFTokenCollection';
import LoginManager from '../LoginManager';

export default class CSRFInput extends HTMLInputElement implements Observer {
    public constructor() {
        super();

        const loginManager = LoginManager.getInstance();
        loginManager.attach(this);
    }

    public async update(): Promise<void> {
        const form = this.closest('form');
        if (form === null) {
            return;
        }

        const token = await this.#getNewCSRFToken(form);
        if (token === null) {
            return;
        }

        this.value = token;
    }

    async #getNewCSRFToken(form: HTMLFormElement): Promise<string | null> {
        const collection = CSRFTokenCollection.getInstance();
        const response = await collection.retrieve({ name: form.name });
        return response?.token ?? null;
    }
}

if (!customElements.get('csrf-input')) {
    customElements.define('csrf-input', CSRFInput, { extends: 'input' });
}
