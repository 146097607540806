import '../../atoms/anchor/Anchor';
import '../../atoms/button/Button';
import '../../atoms/line-divider/LineDivider';
import '../../molecules/form-field/FormField';
import '../form-row/FormRow';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import HttpClient from '@naturehouse/nh-essentials/lib/requests/HttpClient';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
/* eslint-disable import/no-duplicates */
import '../../molecules/status-panel/StatusPanel';
import '../form/AjaxForm';
import { StatusPanelVariant } from '../../molecules/status-panel/StatusPanel';
import AjaxForm, { AjaxResponse } from '../form/AjaxForm';
/* eslint-enable import/no-duplicates */
import type { DefaultComponentType } from '../../../../src/utils/types';
import statusPanelTemplate from '../../molecules/status-panel/StatusPanel.html.njk';
import './GuestRegisterForm.pcss';

export enum GuestRegisterEvents {
    REGISTER_SUCCESS = 'guest-register-success',
    REGISTER_FAILED = 'guest-register-failed'
}

type GuestRegisterFormButtonType = DefaultComponentType & {
    label: string;
    href?: string;
};

type GuestRegisterFormCtaType = DefaultComponentType & {
    label: string;
    anchorText: string;
    href?: string;
    target?: string;
};

type GuestRegisterFormInputType = DefaultComponentType & {
    label: string;
    id: string;
    name: string;
    required?: boolean;
};

export type GuestRegisterFormProps = DefaultComponentType & {
    action: string;
    method: 'GET' | 'POST';
    errorMessage: string;
    email: GuestRegisterFormInputType;
    password: GuestRegisterFormInputType;
    subscribeToNewsletter: GuestRegisterFormInputType;
    privacyPolicyApproved: GuestRegisterFormInputType;
    registerButton: GuestRegisterFormButtonType;
    googleButton: GuestRegisterFormButtonType;
    buttonDivider: GuestRegisterFormButtonType;
    haveAnAccountAlready: GuestRegisterFormCtaType;
    appleButton?: GuestRegisterFormButtonType;
};

export default class GuestRegisterForm extends AjaxForm {
    get errorMessage(): string {
        return this.getAttribute('error-message') ?? 'Something went wrong';
    }

    public async submitForm(event?: Event): Promise<boolean> {
        event?.preventDefault();

        if (this.submitting) return false;

        this.disableSubmitButtons();

        const action = this.getAttribute('action');

        if (!action) {
            throw new Error('No action was set for this AjaxForm');
        }

        const data = Object.fromEntries(new FormData(this));

        let returnStatus = true;
        try {
            const response = (await HttpClient.post(action, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            })) as unknown as Response;

            if (response instanceof HttpError) {
                throw new Error('Register failed');
            }

            this.onSuccess(response);
        } catch (error) {
            this.onError();
            returnStatus = false;
        }

        this.enableSubmitButtons();
        return returnStatus;
    }

    protected onSuccess(res: Response): void {
        this.#removeErrorStatusPanels();
        const response = res as unknown as AjaxResponse;
        this.dispatchEvent(
            new CustomEvent(GuestRegisterEvents.REGISTER_SUCCESS, {
                detail: {
                    isLandlord: response.data?.isLandlord ?? false
                }
            })
        );
    }

    protected onError(): void {
        this.#removeErrorStatusPanels();

        const errorMessage = parseStringAsHtml(
            statusPanelTemplate.render({
                variant: StatusPanelVariant.error,
                label: this.errorMessage,
                data: {
                    'data-role': 'error-status-panel'
                }
            }),
            'div'
        );

        this.insertBefore(errorMessage, this.firstChild);

        this.dispatchEvent(new Event(GuestRegisterEvents.REGISTER_FAILED));
    }

    #removeErrorStatusPanels(): void {
        this.querySelectorAll('[data-role="error-status-panel"]')?.forEach((panel) =>
            panel.remove()
        );
    }
}

if (!window.customElements.get('nh-guest-register-form')) {
    window.customElements.define('nh-guest-register-form', GuestRegisterForm, { extends: 'form' });
}
