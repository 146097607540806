import '../../atoms/button/Button';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import type { DefaultComponentType } from '../../../../src/utils/types';
import type Button from '../../atoms/button/Button';
import './ToggleIconButton.pcss';

export type ToggleIconButtonProps = DefaultComponentType & {
    defaultIcon: IconProps;
    activeIcon: IconProps;
    active?: boolean;
    loading?: boolean;
    disabled?: boolean;
};

export default class ToggleIconButton extends HTMLDivElement {
    static get observedAttributes(): string[] {
        return ['active'];
    }

    readonly #defaultIcon = this.getAttribute('default-icon') || '';

    readonly #activeIcon = this.getAttribute('active-icon') || '';

    get active(): boolean {
        return this.hasAttribute('active');
    }

    set active(value: boolean) {
        this.toggleAttribute('active', value);
    }

    get loading(): boolean {
        if (!this.button) return false;
        return this.button.hasAttribute('loading');
    }

    set loading(value: boolean) {
        if (!this.button) return;
        this.button.toggleAttribute('loading', value);
    }

    protected button: Button | null = null;

    protected connectedCallback(): void {
        this.button = this.querySelector('[is="nh-button"]') as Button;
        this.addEventListener('mouseenter', this.#toggleIcon.bind(this));
        this.addEventListener('mouseleave', this.#toggleIcon.bind(this));

        this.button.setAttribute('icon', this.active ? this.#activeIcon : this.#defaultIcon);
    }

    protected disconnectedCallback(): void {
        this.removeEventListener('mouseenter', this.#toggleIcon.bind(this));
        this.removeEventListener('mouseleave', this.#toggleIcon.bind(this));
    }

    protected attributeChangedCallback(name: string): void {
        if (name === 'active') {
            if (!this.button) return;
            this.button.icon = this.active ? this.#activeIcon : this.#defaultIcon;
        }
    }

    #toggleIcon(event: MouseEvent): void {
        if (!this.button) return;

        const hovered = event.type === 'mouseenter';
        const active = hovered ? !this.active : this.active;

        this.button.icon = active ? this.#activeIcon : this.#defaultIcon;
    }
}

if (!window.customElements.get('toggle-icon-button')) {
    window.customElements.define('toggle-icon-button', ToggleIconButton, { extends: 'div' });
}
