import { getCookieValue, setCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';
import '../../atoms/anchor/Anchor';
import '../../atoms/button/Button';
import type { AnchorProps } from '../../atoms/anchor/Anchor';
import type { ButtonProps } from '../../atoms/button/Button';
import './Banner.pcss';

export enum BannerVariant {
    NOTIFICATION = 'notification',
    INFORMATION = 'information'
}

export type BannerProps = {
    label: string;
    name: string;
    ctaButton?: ButtonProps;
    ctaLink?: AnchorProps;
    variant?: BannerVariant;
    cookieExists?: boolean;
    cookieDuration?: number;
};

export default class Banner extends HTMLElement {
    #cookieName = '';

    get cookieName(): string {
        return this.#cookieName;
    }

    #cookieDuration = this.hasAttribute('cookie-duration')
        ? Number(this.getAttribute('cookie-duration'))
        : 86400;

    get cookieDuration(): number {
        return this.#cookieDuration;
    }

    #name = this.getAttribute('name') ?? '';

    get name(): string {
        return this.#name;
    }

    #closeButton = this.querySelector('[data-role="close"]') as HTMLButtonElement | null;

    get closeButton(): HTMLButtonElement | null {
        return this.#closeButton;
    }

    #ctaButton = this.querySelector('[data-role="cta"]') as HTMLButtonElement | null;

    get ctaButton(): HTMLButtonElement | null {
        return this.#ctaButton;
    }

    public connectedCallback() {
        this.#cookieName = `banner_${this.name}_seen`;
        if (getCookieValue(this.#cookieName) === 'true') {
            this.remove();
            return;
        }

        this.hidden = false;

        this.#closeButton?.addEventListener('click', this.#handleClose);
    }

    public disconnectedCallback() {
        this.#closeButton?.removeEventListener('click', this.#handleClose);
    }

    public setCookie(): void {
        setCookieValue(this.#cookieName, 'true', { 'max-age': this.#cookieDuration });
    }

    #handleClose = () => {
        this.#close();
        this.setCookie();
    };

    #close(): void {
        this.remove();
    }
}

if (!window.customElements.get('nh-banner')) {
    window.customElements.define('nh-banner', Banner);
}
