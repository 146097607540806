import { debounce } from '@naturehouse/nh-essentials/lib/events/eventHandling';
import './carousel.pcss';

class Carousel extends HTMLElement {
    #current = 0;

    #total = 0;

    #shownAmount = 8;

    #carouselElement: HTMLElement | null = null;

    protected connectedCallback(): void {
        this.#carouselElement = this.querySelector('[data-carousel-list]') as HTMLElement;
        this.#total = Array.from(this.querySelectorAll('[data-carousel-item]')).length;

        this.#shownAmount = this.#getShownAmount();

        window.addEventListener(
            'resize',
            debounce(() => {
                this.#changeMediaQuery();
            }, 100)
        );

        this.#setEventListeners();
    }

    #changeMediaQuery(): void {
        this.#shownAmount = this.#getShownAmount();
        this.#navigateCarousel(this.#current);
    }

    #getShownAmount(): number {
        return Number(getComputedStyle(this).getPropertyValue('--item-count'));
    }

    #setEventListeners(): void {
        const navigatePreviousButton: HTMLButtonElement | null =
            this.querySelector('[data-action="prev"]');
        const navigateNextButton: HTMLButtonElement | null =
            this.querySelector('[data-action="next"]');

        if (!navigateNextButton || !navigatePreviousButton) {
            return;
        }

        navigatePreviousButton.addEventListener('click', () => this.#navigate(-1));
        navigateNextButton.addEventListener('click', () => this.#navigate(1));

        this.addEventListener('start-reached', () => {
            navigatePreviousButton.disabled = true;
        });

        this.addEventListener('end-reached', () => {
            navigateNextButton.disabled = true;
        });

        this.addEventListener('navigated', () => {
            navigatePreviousButton.disabled = false;
            navigateNextButton.disabled = false;
        });
    }

    #navigate(increment: number): void {
        this.#current += increment;
        const maxCurrent = this.#total - this.#shownAmount;

        if (this.#current <= 0) {
            this.#current = 0;
        }

        if (this.#current >= maxCurrent) {
            this.#current = maxCurrent;
        }

        this.#navigateCarousel(this.#current);
        this.dispatchEvent(new CustomEvent('navigated'));

        if (this.#current === 0) {
            this.dispatchEvent(new CustomEvent('start-reached'));
            return;
        }

        if (this.#current === maxCurrent) {
            this.dispatchEvent(new CustomEvent('end-reached'));
        }
    }

    #navigateCarousel(offset: number): void {
        if (this.#carouselElement === null) {
            return;
        }

        this.#carouselElement.style.transform = `translateX(${this.#getTranslateX(offset)}px)`;
    }

    #getItemWidth(): number {
        const item: HTMLLIElement | null = this.querySelector('[data-carousel-item]');

        if (!item || !item.parentElement) {
            return 0;
        }

        const flexStyle = getComputedStyle(item.parentElement);
        const gap = parseInt(flexStyle.gap, 10) || 0;

        return item.getBoundingClientRect().width + gap;
    }

    #getTranslateX(count: number): number {
        const itemWidth: number = this.#getItemWidth();
        return -(itemWidth * count);
    }
}

if (!customElements.get('nh-carousel')) {
    customElements.define('nh-carousel', Carousel);
}
