import * as Bowser from 'bowser';
import * as StackTrace from 'stacktrace-js';
import ErrorBase, {
    ErrorReport,
    ErrorSeverity
} from '@naturehouse/nh-essentials/lib/exceptions/ErrorBase.js';
import supportedBrowsers from '../../../supportedBrowsers.js';

enum ErrorService {
    UNKNOWN = 'unknown',
    NATURE_HOUSE = 'Nature.House',
    DESIGN_SYSTEM = 'design-system',
    ESSENTIALS = 'essentials'
}

declare global {
    interface Window {
        logUrl: string | undefined;
    }
}

// we filter these matches in the userAgent string
const agentFilter = ['PingdomPageSpeed', 'AdsBot-Google', 'Googlebot', 'Applebot'];
const fileFilter = ['custom-elements/index.js'];

export default class ErrorLogger {
    readonly #errorHandler = (event: ErrorEvent): Promise<void> => this.#reportError(event.error);

    readonly #unhandledRejectionHandler = (event: PromiseRejectionEvent): Promise<void> =>
        this.#reportError(event.reason);

    private constructor() {
        window.addEventListener('error', this.#errorHandler);
        window.addEventListener('unhandledrejection', this.#unhandledRejectionHandler);
    }

    public static init(): ErrorLogger {
        return new ErrorLogger();
    }

    public destroy(): void {
        window.removeEventListener('error', this.#errorHandler);
        window.removeEventListener('unhandledrejection', this.#unhandledRejectionHandler);
    }

    #getService(fileName: string): string {
        if (fileName.includes('design-system')) {
            return ErrorService.DESIGN_SYSTEM;
        }

        if (fileName.includes('nh-essentials')) {
            return ErrorService.ESSENTIALS;
        }

        return ErrorService.NATURE_HOUSE;
    }

    #getVersion(): string {
        return 'unknown';
    }

    async #reportError(error: ErrorBase): Promise<void> {
        if (window.logUrl === undefined) return;

        const userAgent = window.navigator.userAgent;
        for (const filter of agentFilter) {
            if (userAgent.includes(filter)) {
                return;
            }
        }

        if (!supportedBrowsers.test(navigator.userAgent)) {
            return;
        }

        try {
            const stacktrace = await StackTrace.fromError(error);
            const system = Bowser.parse(userAgent);

            const frame = stacktrace[0];
            const fileName = frame.fileName;

            if (!fileName?.startsWith('webpack://')) {
                return;
            }

            for (const filter of fileFilter) {
                if (fileName.includes(filter)) {
                    return;
                }
            }

            const payload = this.#getPayload(error, frame, stacktrace, system);
            await fetch(window.logUrl, {
                body: JSON.stringify(payload),
                method: 'POST',
                mode: 'cors',
                credentials: 'omit',
                headers: { 'Content-Type': 'application/json' }
            });
            // eslint-disable-next-line no-empty
        } catch {}
    }

    #getPayload(
        error: ErrorBase,
        frame: StackTrace.StackFrame,
        stacktrace: StackTrace.StackFrame[],
        system: Bowser.Parser.ParsedResult
    ): ErrorReport {
        const fileName = stacktrace[0].fileName as unknown as string;
        const service = this.#getService(fileName);
        const version = this.#getVersion();
        const report: ErrorReport = {
            eventTime: new Date().toUTCString(),
            message: error.message,
            severity: error.severity ?? ErrorSeverity.ERROR,
            httpRequest: {
                url: window.location.href,
                userAgent: window.navigator.userAgent,
                referrer: document.referrer,
                method: 'POST',
                remoteIp: 'omit',
                responseStatusCode: 200
            },
            context: {
                lineNumber: Number(frame.lineNumber),
                columnNumber: Number(frame.columnNumber),
                fileName: String(frame.fileName),
                error: error.message,
                stacktrace: stacktrace.map((stackTraceFrame) => ({
                    ...frame,
                    columnNumber: stackTraceFrame.columnNumber || 0,
                    lineNumber: stackTraceFrame.lineNumber || 0,
                    fileName: stackTraceFrame.fileName || '',
                    functionName: stackTraceFrame.functionName || ''
                })),
                service: service,
                version: version,
                system: {
                    ...system,
                    browser: {
                        ...system.browser,
                        name: system.browser?.name || '',
                        version: system.browser?.version || ''
                    },
                    os: {
                        ...system.os,
                        name: system.os?.name || '',
                        version: system.os?.version || '',
                        versionName: system.os?.versionName || ''
                    },
                    platform: {
                        ...system.platform,
                        type: system.platform?.type || '',
                        vendor: system.platform?.vendor || ''
                    },
                    engine: {
                        ...system.engine,
                        name: system.engine?.name || ''
                    }
                },
                loggingDetails: error.loggingDetails || []
            }
        };

        return report;
    }
}
