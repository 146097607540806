import '../../../../src/components/protons/icon/Icon';
import '../../atoms/label/Label';
import '../../atoms/input/Input';
import '../../atoms/button/Button';
import type { LabelProps } from '../../atoms/label/Label';
import type { InputProps } from '../../atoms/input/Input';
import './InputIncrementer.pcss';

type PluralisedLabel = {
    singular: string;
    plural: string;
};

export type InputIncrementerProps = {
    input: InputProps;
    label: LabelProps;
    text?: PluralisedLabel;
    prefix?: string;
};

export default class InputIncrementer extends HTMLElement {
    #input = this.querySelector('input') as HTMLInputElement;

    #minButton = this.querySelector('.input-incrementer__button--min') as HTMLButtonElement;

    #plusButton = this.querySelector('.input-incrementer__button--plus') as HTMLButtonElement;

    #labelText = this.querySelector('.input-incrementer__text') as HTMLSpanElement;

    #inputMin = Number(this.#input.min);

    #inputMax = this.#input.max ? Number(this.#input.max) : null;

    #inputValue = Number(this.#input.value);

    get value(): number {
        return Number(this.#input.value);
    }

    set value(value: number) {
        if (value < this.#inputMin || (this.#inputMax && value > this.#inputMax)) {
            return;
        }

        this.#input.value = value.toString();
    }

    get disabled(): boolean {
        return this.hasAttribute('disabled');
    }

    set disabled(value: boolean) {
        this.toggleAttribute('disabled', value);
    }

    protected connectedCallback(): void {
        if (!this.value) {
            this.value = this.#inputMin;
        }

        this.#input.addEventListener('input', this.#handleInputChange.bind(this, false));
        this.#input.addEventListener('blur', this.#handleInputChange.bind(this, true));

        this.#input.addEventListener(
            'disable-add-button',
            this.#setDisabledPlusButton.bind(this, true)
        );

        this.#input.addEventListener(
            'enable-add-button',
            this.#setDisabledPlusButton.bind(this, false)
        );

        this.#minButton.addEventListener('click', this.#handleMinButtonClick.bind(this));
        this.#plusButton.addEventListener('click', this.#handlePlusButtonClick.bind(this));

        this.#updateVisualState(this.#inputValue);
    }

    protected disconnectedCallback(): void {
        this.#input.removeEventListener('input', this.#handleInputChange.bind(this, false));
        this.#input.removeEventListener('blur', this.#handleInputChange.bind(this, true));

        this.#input.removeEventListener(
            'disable-add-button',
            this.#setDisabledPlusButton.bind(this, true)
        );

        this.#input.removeEventListener(
            'enable-add-button',
            this.#setDisabledPlusButton.bind(this, false)
        );

        this.#minButton.removeEventListener('click', this.#handleMinButtonClick.bind(this));
        this.#plusButton.removeEventListener('click', this.#handlePlusButtonClick.bind(this));
    }

    #handleMinButtonClick(): void {
        this.#input.stepDown();
        this.#input.dispatchEvent(new Event('change'));
        this.#handleInputChange(false);
    }

    #handlePlusButtonClick(): void {
        this.#input.stepUp();
        this.#input.dispatchEvent(new Event('change'));
        this.#handleInputChange(false);
    }

    #setDisabledPlusButton(value: boolean): void {
        this.#plusButton.disabled = value;
    }

    #handleInputChange(blurEvent = false): void {
        const leadingZerosRegex = /^0+/;
        const leadingZeros: RegExpMatchArray | [] =
            this.#input.value.match(leadingZerosRegex) || [];

        if (leadingZeros[0] && leadingZeros[0].length && this.#input.value.length > 1) {
            const newValue: string = this.#input.value.replace(
                leadingZerosRegex,
                this.#inputMin.toString()
            );
            this.#changeInputValue(parseInt(newValue, 10));
            return;
        }

        if (this.#inputMax && this.#input.valueAsNumber > this.#inputMax) {
            this.#changeInputValue(this.#inputMax);
            return;
        }

        const inputIsSmallerThanAllowed: boolean = this.#input.valueAsNumber < this.#inputMin;
        const noValueWhenInputIsBlurred: boolean = this.#input.value.length === 0 && blurEvent;

        if (inputIsSmallerThanAllowed || noValueWhenInputIsBlurred) {
            this.#changeInputValue(this.#inputMin);
            return;
        }

        if (Number.isNaN(this.#input.valueAsNumber)) {
            this.#updateVisualState(null);
            return;
        }

        this.#changeInputValue(this.#input.valueAsNumber);
    }

    #changeInputValue(newValue: number): void {
        this.#input.value = newValue.toString();
        this.#inputValue = newValue;
        this.#updateVisualState(newValue);
        this.dispatchEvent(new CustomEvent('change', { detail: newValue }));
    }

    #updateVisualState(newValue: number | null): void {
        if (this.disabled) {
            this.#minButton.disabled = true;
            this.#plusButton.disabled = true;
            return;
        }

        this.#minButton.disabled = newValue === this.#inputMin;
        this.#plusButton.disabled = this.#inputMax !== null && newValue === this.#inputMax;

        this.#setLabelText();
    }

    #setLabelText(): void {
        const singular = this.dataset.labelSingular;
        const plural = this.dataset.labelPlural;

        if (!singular || !plural) {
            return;
        }

        this.#labelText.innerText = this.#inputValue === 1 ? singular : plural;
    }
}

if (!window.customElements.get('nh-input-incrementer')) {
    window.customElements.define('nh-input-incrementer', InputIncrementer);
}
