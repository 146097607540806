import type Button from '../../atoms/button/Button';
import './Table.pcss';

export enum ColTypes {
    TEXT = 'text',
    ACTIONS = 'actions'
}

export enum TableEvents {
    ROW_CLICK = 'rowCLick',
    ACTION_CLICK = 'actionClick'
}

export type TableHead = {
    label: string;
};

type CellType = {
    content: string | HTMLElement;
};

export type TableRow = {
    id: string;
    href?: string;
    cells: CellType[];
};

type TableColConfig = {
    id: string;
    title?: boolean;
    type?: ColTypes;
    hideMobile?: boolean;
    hideDesktop?: boolean;
};

type TableConfig = {
    cols: TableColConfig[];
};

export type TableProps = {
    head?: TableHead[];
    rows: TableRow[];
    config: TableConfig;
};

/**
 * @deprecated This component is deprecated. It does too much crap.
 */
export default class TableNew extends HTMLTableElement {
    #tableBody = this.querySelector('tbody');

    #rowClickHandler = this.#handleRowClick.bind(this);

    protected connectedCallback(): void {
        this.#tableBody?.addEventListener('click', this.#rowClickHandler);
    }

    protected disconnectedCallback(): void {
        this.#tableBody?.removeEventListener('click', this.#rowClickHandler);
    }

    #handleRowClick(event: Event) {
        const clickElement = event.target as HTMLElement;
        const tr = clickElement.closest('tr');

        if (clickElement.tagName === 'BUTTON') {
            this.#handleButtonClick(clickElement as Button, tr?.dataset.id);
            return;
        }
        const button = clickElement.closest('button');
        if (button) {
            this.#handleButtonClick(button as Button, tr?.dataset.id);
            return;
        }

        const td = clickElement.closest('td');
        if (td?.dataset.type === ColTypes.ACTIONS) {
            return;
        }

        if (tr?.dataset.href) {
            const url = new URL(tr?.dataset.href);
            window.location.href = url.toString();
            return;
        }

        this.dispatchEvent(
            new CustomEvent(TableEvents.ROW_CLICK, {
                detail: { id: tr?.dataset.id }
            })
        );
    }

    #handleButtonClick(clickElement: Button, id: string | undefined) {
        this.dispatchEvent(
            new CustomEvent(TableEvents.ACTION_CLICK, {
                detail: {
                    role: clickElement.dataset.role,
                    id: id || null
                }
            })
        );
    }
}

if (!window.customElements.get('nh-table-new')) {
    window.customElements.define('nh-table-new', TableNew, { extends: 'table' });
}
