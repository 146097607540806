import '../../../../src/components/protons/icon/Icon';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import type { DefaultComponentType } from '../../../../src/utils/types';
import type { InputProps } from '../input/Input';
import chipTemplate from './Chip.html.njk';
import './Chip.pcss';

export type ChipProps = DefaultComponentType & {
    input: InputProps;
    label: string;
    helpText?: string;
    errorText?: string;
    icon?: IconProps;
};

export default class Chip extends HTMLElement {
    constructor(props?: ChipProps) {
        super();

        if (props) {
            this.innerHTML = chipTemplate.render(props);
        }
    }
}

if (!window.customElements.get('nh-chip')) {
    window.customElements.define('nh-chip', Chip);
}
