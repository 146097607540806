import Curtain from '@naturehouse/design-system/components/molecules/curtain/Curtain';
import { matchMediaAddEventListener } from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';

export default class MobileCurtainContainer extends HTMLDivElement {
    readonly #tabletMediaQuery: MediaQueryList;

    readonly #curtains: Curtain[] = Array.from(this.querySelectorAll('[is="nh-curtain"]'));

    constructor() {
        super();

        const tabletLandscape = getComputedStyle(document.documentElement).getPropertyValue(
            '--tablet-landscape'
        );
        this.#tabletMediaQuery = window.matchMedia(tabletLandscape);

        matchMediaAddEventListener(this.#tabletMediaQuery, () => {
            this.setForceOpen(this.#tabletMediaQuery.matches);
        });

        this.setForceOpen(this.#tabletMediaQuery.matches);
    }

    setForceOpen(isDesktop: boolean): void {
        for (const curtain of this.#curtains) {
            curtain.forceOpen = isDesktop;
        }
    }
}

if (!customElements.get('curtain-container')) {
    customElements.define('curtain-container', MobileCurtainContainer, { extends: 'div' });
}
