import UnavailableMethodException from '@naturehouse/nh-essentials/lib/exceptions/UnavailableMethodException';
import HttpError from '@naturehouse/nh-essentials/lib/requests/HttpError';
import CollectionBase from '../../common/CollectionBase';

export type CSRFTokenReturnType = {
    token: string;
};

export type CSRFTokenType = {
    name: string;
};

export default class CSRFTokenCollection extends CollectionBase<CSRFTokenType> {
    static #instance: CSRFTokenCollection | null = null;

    public static getInstance(): CSRFTokenCollection {
        if (this.#instance === null) {
            this.#instance = new CSRFTokenCollection();
        }

        return this.#instance;
    }

    private constructor() {
        super();
    }

    public async add(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public retrieve<RT = CSRFTokenReturnType>(data: CSRFTokenType): Promise<RT | void> {
        return super.retrieve<CSRFTokenReturnType>(data) as Promise<RT | void>;
    }

    public async delete(): Promise<void> {
        throw new UnavailableMethodException();
    }

    public async update(): Promise<void> {
        throw new UnavailableMethodException();
    }

    protected endpoint(params: CSRFTokenType): string {
        return `/api/csrf/refresh?name=${params.name}`;
    }

    protected onError(error: HttpError<AjaxResponseError>): void {
        throw new Error(error.body?.message);
    }
}
