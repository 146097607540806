var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/protons/icon/Icon.macro.njk"] = require( "@naturehouse/design-system/components/protons/icon/Icon.macro.njk" );
dependencies["@naturehouse/design-system/components/atoms/anchor/Anchor.macro.njk"] = require( "@naturehouse/design-system/components/atoms/anchor/Anchor.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/form-field/HelpText.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
if(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "help"),null) != null && env.getFilter("length").call(context, env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"text"),"")) > 0) {
output += "\n    <p id=\"";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "input")),"id") + "" + "-help-text", env.opts.autoescape);
output += "\" class=\"nh-form-field__help-text\">\n        ";
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"secure"),false) == true) {
output += "\n            ";
env.getTemplate("@naturehouse/design-system/components/protons/icon/Icon.macro.njk", false, "design-system/v3/components/molecules/form-field/HelpText.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("iconTemplate", t_1);
output += "\n            ";
output += runtime.suppressValue((lineno = 4, colno = 34, runtime.callWrap(runtime.memberLookup((t_1),"render"), "iconTemplate[\"render\"]", context, [{"name": "secure","size": 0.9,"custom": true}])), env.opts.autoescape);
output += "\n        ";
})});
}
output += "\n        <span>\n            ";
output += runtime.suppressValue(runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"text"), env.opts.autoescape);
output += "\n        </span>\n        ";
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"link"),null) != null && env.getFilter("default").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"link")),"href"),"") != "" && env.getFilter("default").call(context, runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"link")),"label"),"") != "") {
output += "\n            ";
env.getTemplate("@naturehouse/design-system/components/atoms/anchor/Anchor.macro.njk", false, "design-system/v3/components/molecules/form-field/HelpText.html.njk", false, function(t_5,t_4) {
if(t_5) { cb(t_5); return; }
t_4.getExported(function(t_6,t_4) {
if(t_6) { cb(t_6); return; }
context.setVariable("anchorTemplate", t_4);
output += "\n            ";
output += runtime.suppressValue((lineno = 15, colno = 36, runtime.callWrap(runtime.memberLookup((t_4),"render"), "anchorTemplate[\"render\"]", context, [{"href": runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"link")),"href"),"label": runtime.memberLookup((runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "help")),"link")),"label"),"variant": "inline"}])), env.opts.autoescape);
output += "\n        ";
})});
}
output += "\n    </p>";
;
}
output += "\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/form-field/HelpText.html.njk"] , dependencies)