import type { DefaultComponentType } from '../../../../src/utils/types';
import './Loader.pcss';

export type LoaderProps = DefaultComponentType & {
    width?: number;
    height?: number;
    rows?: number;
    animation?: boolean;
};

export default class Loader extends HTMLElement {
    #width: number = 0;

    get width(): number {
        return this.#width;
    }

    set width(value: number) {
        this.#width = value;
        this.style.setProperty('--width', `${value}px`);
    }

    #height: number = 0;

    get height(): number {
        return this.#height;
    }

    set height(value: number) {
        this.#height = value;
        this.style.setProperty('--height', `${value}px`);
    }

    #rows: number = 0;

    get rows(): number {
        return this.#rows;
    }

    set rows(value: number) {
        this.#rows = value;

        if (!this.parentElement) {
            return;
        }

        const cs: CSSStyleDeclaration = getComputedStyle(this.parentElement);
        const lineHeight: number = Number(cs.getPropertyValue('line-height').slice(0, -2)) || 16;
        this.height = lineHeight * value;
    }

    #animation: boolean = false;

    get animation(): boolean {
        return this.#animation;
    }

    set animation(value: boolean) {
        this.#animation = true;
        this.classList.toggle('nh-loader--animation', value);
    }

    protected connectedCallback(): void {
        if (this.hasAttribute('width')) {
            this.width = Number(this.getAttribute('width'));
        }

        if (this.hasAttribute('height')) {
            this.height = Number(this.getAttribute('height'));
        }

        if (this.hasAttribute('rows')) {
            this.rows = Number(this.getAttribute('rows'));
        }

        this.animation = this.hasAttribute('animation');
    }
}

if (!window.customElements.get('nh-loader')) {
    window.customElements.define('nh-loader', Loader);
}
