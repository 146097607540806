declare global {
    interface Window {
        grecaptcha: ReCaptchaInstance;
        captchaOnLoad: () => void;
        recaptchaCallback: () => void;
    }
}

interface ReCaptchaInstance {
    ready: (cb: () => any) => void;
    execute: (siteKey: string, options: ReCaptchaExecuteOptions) => Promise<string>;
    render: (id: string, options: ReCaptchaRenderOptions) => any;
}

interface ReCaptchaExecuteOptions {
    action: string;
}

interface ReCaptchaRenderOptions {
    sitekey: string;
    size: 'invisible';
}

export default class RecaptchaManager {
    readonly #formElements: HTMLInputElement[];

    readonly #captchaField: HTMLInputElement;

    constructor(form: HTMLFormElement) {
        this.#formElements = Array.from(
            form.querySelectorAll(
                `
                    form[data-recaptcha="invisible-recaptcha"] input,
                    form[data-recaptcha="invisible-recaptcha"] select,
                    form[data-recaptcha="invisible-recaptcha"] button,
                    form[data-recaptcha="invisible-recaptcha"] textarea
                `
            )
        );

        this.#captchaField = form.querySelector(
            'input[type="hidden"][data-name="recaptcha"]'
        ) as HTMLInputElement;
    }

    public initialize(): void {
        if (!this.#formElements.length || !this.#captchaField) {
            return;
        }

        let loaded = false;

        window.recaptchaCallback = (): void => {
            const siteKey = this.#captchaField.dataset.token;
            const action = this.#captchaField.dataset.action;

            if (!siteKey || !action) {
                return;
            }

            const grecaptcha = window.grecaptcha;

            grecaptcha.execute(siteKey, { action: action }).then((token: string) => {
                this.#captchaField.value = token;
            });
        };

        const callback = (): void => {
            if (!loaded && this.#captchaField !== null) {
                const sitekey = this.#captchaField.dataset.token;
                const head = document.getElementsByTagName('head')[0];
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}&onload=recaptchaCallback`;
                head.appendChild(script);
                loaded = true;
            }

            this.#formElements.forEach((element: HTMLInputElement) => {
                ['focus', 'click', 'input', 'change', 'propertychange'].forEach((event: string) => {
                    element.removeEventListener(event, callback);
                });
            });
        };

        this.#formElements.forEach((element: HTMLInputElement) => {
            ['focus', 'click', 'input', 'change', 'propertychange'].forEach((event: string) => {
                element.addEventListener(event, callback);
            });
        });
    }
}
