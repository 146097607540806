import getAbsoluteHeight from '@naturehouse/nh-essentials/lib/calculators/height';
import '../../../../src/components/protons/icon/Icon';
import type { DefaultComponentType } from '../../../../src/utils/types';
import './Curtain.pcss';

export enum CurtainVariant {
    INLINE = 'inline'
}

export type CurtainProps = DefaultComponentType & {
    open: boolean;
    forceOpen?: boolean;
    title: string;
    subtitle?: string;
    content: string;
    variant?: CurtainVariant;
};

export default class Curtain extends HTMLDetailsElement {
    readonly #summary: HTMLElement;

    readonly #content: HTMLElement;

    #openState = false;

    #forceOpen = false;

    set forceOpen(value: boolean) {
        this.classList.toggle('nh-curtain-new--force-open', value);
        this.#forceOpen = value;

        if (value) {
            this.#openState = this.open;
            this.open = value;

            return;
        }

        this.open = this.#openState;
    }

    #animation: Animation | null = null;

    constructor() {
        super();

        this.#summary = this.querySelector('[data-role="summary"]') as HTMLElement;
        this.#content = this.querySelector('[data-role="content"]') as HTMLElement;
    }

    protected connectedCallback(): void {
        if (!this.#summary || !this.#content) {
            return;
        }

        this.#summary.addEventListener('click', this.handleClick.bind(this));
    }

    protected disconnectedCallback(): void {
        if (!this.#summary || !this.#content) {
            return;
        }

        this.#summary.removeEventListener('click', this.handleClick.bind(this));
    }

    protected handleClick(event: Event): void {
        event.preventDefault();

        if (event.target instanceof HTMLAnchorElement) {
            return;
        }

        this.toggle();
    }

    public toggle(): void {
        if (this.#forceOpen) {
            return;
        }

        if (this.open) {
            this.close();
            return;
        }

        this.#open();
    }

    #open() {
        if (!this.open) {
            this.open = true;
        }

        window.requestAnimationFrame(() => {
            const startHeight = `${this.offsetHeight - getAbsoluteHeight(this.#content)}px`;
            const endHeight = `${this.offsetHeight}px`;

            if (this.#animation) {
                this.#animation.cancel();
            }

            this.classList.add('nh-curtain-new--open');
            this.#animation = this.animate(
                {
                    height: [startHeight, endHeight]
                },
                {
                    duration: 400,
                    easing: 'ease-out'
                }
            );

            this.#animation.onfinish = () => this.#onAnimationFinish(true);
        });
    }

    close() {
        const startHeight = `${this.offsetHeight}px`;
        const endHeight = `${this.offsetHeight - getAbsoluteHeight(this.#content)}px`;

        if (this.#animation) {
            this.#animation.cancel();
        }

        this.classList.remove('nh-curtain-new--open');
        this.#animation = this.animate(
            {
                height: [startHeight, endHeight]
            },
            {
                duration: 200,
                easing: 'ease-out'
            }
        );

        this.#animation.onfinish = () => this.#onAnimationFinish(false);
    }

    #onAnimationFinish(open: boolean) {
        this.open = open;
        this.#animation = null;
        this.style.height = '';
    }
}

if (!window.customElements.get('nh-curtain')) {
    window.customElements.define('nh-curtain', Curtain, { extends: 'details' });
}
