import Dialog from '@naturehouse/design-system/components/molecules/dialog/Dialog';
import { getCookieValue, setCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';

export default class RedirectPopup {
    public initialize(): void {
        const alreadyShown = getCookieValue('redirect_popup');

        if (alreadyShown !== null) {
            return;
        }

        const dialog = document.getElementById('redirect-dialog') as Dialog;
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const domain = params.get('redirect-from');

        if (dialog === null || domain === null) {
            return;
        }

        dialog.showModal();
        setCookieValue('redirect_popup', 'true');
    }
}
