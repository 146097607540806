import AjaxForm from '@naturehouse/design-system/components/organisms/form/AjaxForm';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import GoogleAnalyticsEvents from '../../../common/GoogleAnalyticsEvents';
import RecaptchaManager from '../RecaptchaManager';

export default class NewsletterForm extends AjaxForm {
    public connectedCallback(): void {
        super.connectedCallback();

        const recaptchaManager = new RecaptchaManager(this);
        recaptchaManager.initialize();
    }

    protected onSuccess(): void {
        const succesMessageElement: HTMLElement | null =
            this.parentElement?.querySelector('.notification--success') ?? null;

        if (succesMessageElement) {
            succesMessageElement.hidden = false;
            this.hidden = true;
        }

        const formData = new FormData(this);
        const email = formData.get('newsletter_subscriber_form[emailAddress]') as string;
        this.#trackNewsletterSubscription(email);
    }

    protected onError(error: Error): void {
        const errorList = parseStringAsHtml(
            `
            <ul class="error-list">
                <li class="error">${error.message}</li>
            </ul>
        `,
            'ul'
        ) as HTMLUListElement;
        this.insertBefore(errorList, this.firstChild);
    }

    #trackNewsletterSubscription(email: string): void {
        const customGmail: boolean = email.includes('@gmail.com');
        const event: GoogleAnalytics4EmailOptInEvent = {
            email: email,
            newsletter: 'yes',
            service: 'yes',
            marketing: 'yes',
            custom_gmail: customGmail ? 'yes' : ''
        };

        GoogleAnalyticsEvents.trackEvent<GoogleAnalytics4EmailOptInEvent>('EmailOptIn', event);
    }
}

if (!customElements.get('newsletter-form')) {
    customElements.define('newsletter-form', NewsletterForm, { extends: 'form' });
}
